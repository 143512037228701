import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';

import { withAuthentication } from './Session';

import Dashboard from './Dashboard';
import Login from './Login';
import Admin from './Admin';

class App extends React.Component {
  render() {
    return (
      <Router basename="/cr9">
        <Route exact path="/" component={Dashboard}></Route>
        <Route path="/login" component={Login}></Route>
        <Route path="/admin" component={Admin}></Route>
      </Router>
    );
  }
}

export default withAuthentication(App);
