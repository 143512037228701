import React from 'react';

import { AuthUserContext, withAuthorization } from './Session';

import Logout from './Logout';

class Admin extends React.Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <h1>Admin</h1>
            <Logout />
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Admin);
