import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: "AIzaSyAUzzBFd_1kAEg_103XUGCNTAWXZ2bwTFk",
  authDomain: "mf-cr9.firebaseapp.com",
  databaseURL: "https://mf-cr9.firebaseio.com",
  projectId: "mf-cr9",
  storageBucket: "mf-cr9.appspot.com",
  messagingSenderId: "496387614136",
  appId: "1:496387614136:web:978b4d6fa84b6224d19b4d"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
  }

  /* Auth API */
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  projection = () => this.db.ref('projection');

  characters = () => this.db.ref('characters');
  places = () => this.db.ref('places');
  assets = () => this.db.ref('assets');
  quotes = () => this.db.ref('quotes');

  drawn = () => this.db.ref('drawn');
  searchers = () => this.db.ref('searchers');
  teams = () => this.db.ref('teams');
}

export default Firebase;
