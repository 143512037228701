import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from './Firebase';

const Login = () => (
  <div>
    <h1>Connexion</h1>
    <LoginForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
class LoginFormBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/admin');
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email"
        />
        <input
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Mot de passe"
        />
        <button disabled={isInvalid} type="submit">
          Se connecter
        </button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}
const LoginForm = compose(
  withRouter,
  withFirebase,
)(LoginFormBase);

export default Login;
export { LoginForm };
