import React from 'react';

import { withFirebase } from './Firebase';

const Logout = ({ firebase }) => (
  <button type="button" onClick={firebase.doSignOut}>
    Déconnexion
  </button>
);

export default withFirebase(Logout);