import React from 'react';
import './dashboard.css';

import { withFirebase } from './Firebase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faArrowCircleUp, faArrowCircleDown, faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

library.add(faCircle, faArrowCircleUp, faArrowCircleDown, faArrowCircleLeft, faArrowCircleRight);

const AVAILABLE_DIGITS = ['0','1','2','3','4','5','6','7','8','9','/','-'];
const NB_DIGITS = 18;
const NB_BUTTONS = 18;
const NB_BARS = 6;
const THEME = 'CR9-ANTICIPATION';

class Dashboard extends React.Component {

  slowUiInterval = null
  mediumUiInterval = null;
  fastUiInterval = null;

  constructor(props) {
    super(props);

    this.state = {
      projDurationMinutes: 140,
      teams: [],
      searchers: [],
      drawn: {
        character: '',
        place: '',
        asset: '',
        quote: '',
      },
      characters: [],
      places: [],
      assets: [],
      quotes: [],
      ui: {
        buttons: Array(NB_BUTTONS).fill(0),
        digits: {
          row1: Array(NB_DIGITS).fill('/'),
          row2: Array(NB_DIGITS).fill('/'),
          row3: Array(NB_DIGITS).fill('/'),
        },
        questioned: {
          character: 0,
          place: 0,
          asset: 0,
          quote: 0,
        },
        roulette: 0,
        squareNb: 0,
        bars: Array(NB_BARS).fill(50),
      },
    };
  }

  componentDidMount() {
    // General data
    this.props.firebase.projection().on('value', snapshot => {
      const projection = snapshot.val();

      this.setState({projDurationMinutes: projection.duration});
    });

    // Elements
    this.props.firebase.characters().on('value', snapshot => {
      const rawCharacters = snapshot.val();
      const characters = Object.keys(rawCharacters).map(key => ({
        ...rawCharacters[key],
        id: key,
      }));

      this.setState({characters});
    });
    this.props.firebase.places().on('value', snapshot => {
      const rawPlaces = snapshot.val();
      const places = Object.keys(rawPlaces).map(key => ({
        ...rawPlaces[key],
        id: key,
      }));

      this.setState({places});
    });
    this.props.firebase.assets().on('value', snapshot => {
      const rawAssets = snapshot.val();
      const assets = Object.keys(rawAssets).map(key => ({
        ...rawAssets[key],
        id: key,
      }));

      this.setState({assets});
    });
    this.props.firebase.quotes().on('value', snapshot => {
      const rawQuotes = snapshot.val();
      const quotes = Object.keys(rawQuotes).map(key => ({
        ...rawQuotes[key],
        id: key,
      }));

      this.setState({quotes});
    });

    // Elements drawn
    this.props.firebase.drawn().on('value', snapshot => {
      const drawn = snapshot.val();

      this.setState({drawn});
    });

    // Teams
    this.props.firebase.teams().on('value', snapshot => {
      const rawTeams = snapshot.val();
      const teams = Object.keys(rawTeams).map(key => ({
        ...rawTeams[key],
        id: key,
      }));

      this.setState({teams});
    });

    // Searchers
    this.props.firebase.searchers().on('value', snapshot => {
      const rawSearchers = snapshot.val();
      const searchers = Object.keys(rawSearchers).map(key => ({
        ...rawSearchers[key],
        id: key,
      }));

      this.setState({searchers});
    });

    this.slowUiInterval = setInterval(this.updateSlowUI, 5000);
    this.mediumUiInterval = setInterval(this.updateMediumUI, 1000);
    this.fastUiInterval = setInterval(this.updateFastUI, 200);
  }

  componentWillUnmount() {
    this.props.firebase.projection().off();
    this.props.firebase.characters().off();
    this.props.firebase.places().off();
    this.props.firebase.assets().off();
    this.props.firebase.quotes().off();
    this.props.firebase.drawn().off();
    this.props.firebase.teams().off();
    this.props.firebase.searchers().off();

    clearInterval(this.slowUiInterval);
    clearInterval(this.mediumUiInterval);
    clearInterval(this.fastUiInterval);
  }

  updateSlowUI = () => {

  }

  updateMediumUI = () => {
    const newButtons = Array(NB_BUTTONS).fill(0).map(_ => Math.floor(Math.random() * 3));
    const newQuestioned = {
      character: Math.floor(Math.random() * this.state.characters.length),
      place: Math.floor(Math.random() * this.state.places.length),
      asset: Math.floor(Math.random() * this.state.assets.length),
      quote: Math.floor(Math.random() * this.state.quotes.length),
    };
    const newSquareNb = (this.state.ui.squareNb + 1) % THEME.length;

    this.setState({
      ui: Object.assign({}, this.state.ui, {
        buttons: newButtons,
        questioned: newQuestioned,
        squareNb: newSquareNb,
      }),
    });
  }

  updateFastUI = () => {
    const availDigitsSize = AVAILABLE_DIGITS.length;
    const initDigits = Array(NB_DIGITS).fill('/');
    const newDigits = {
      row1: initDigits.map(_ => AVAILABLE_DIGITS[Math.floor(Math.random() * availDigitsSize)]),
      row2: initDigits.map(_ => AVAILABLE_DIGITS[Math.floor(Math.random() * availDigitsSize)]),
      row3: initDigits.map(_ => AVAILABLE_DIGITS[Math.floor(Math.random() * availDigitsSize)]),
    };

    const newRoulette = (this.state.ui.roulette + 1) % 8;

    const newBars = this.state.ui.bars.map(level => {
      let newLevel = level + Math.floor(Math.random() * 7) - 3;
      if (newLevel < 10) {
        newLevel = 10;
      } else if (newLevel > 100) {
        newLevel = 100;
      }
      return newLevel;
    });

    this.setState({
      ui: Object.assign({}, this.state.ui, {
        digits: newDigits,
        roulette: newRoulette,
        bars: newBars,
      }),
    });
  }

  render() {
    return (
      <div>
        <h1>Court-Roulette 9 - Manual Focus</h1>

        <div className="row-general">
          {this.renderData()}
          {this.renderRoulette()}
          {this.renderSearchers()}
        </div>

        <div className="row-elements">
          {/* Elements for roulette */}
          {this.renderCharacters()}
          {this.renderPlaces()}
          {this.renderAssets()}
          {this.renderQuotes()}

          {/* Decorative elements */}
          {this.renderButtons()}
          {this.renderDigits()}
          {this.renderBars()}
          {this.renderProgress()}
        </div>
      </div>
    );
  }

  formatDuration = (rawSeconds) => {
    const hours = Math.floor(rawSeconds / 3600);
    const minutes = Math.floor(rawSeconds / 60) % 60;
    const seconds = Math.floor(rawSeconds) % 60;

    const hPart = (hours > 0) ? `${String(hours).padStart(2, '0')} h ` : '';
    const mPart = `${String(minutes).padStart(2, '0')} min `;
    const sPart = (seconds > 0) ? `${String(seconds).padStart(2, '0')} s` : '';

    return `${hPart}${mPart}${sPart}`;
  }

  renderData() {
    const nbTeams = this.state.teams.length;
    const projDurationSec = this.state.projDurationMinutes * 60;
    // const projDurationStr = this.formatDuration(projDurationSec);

    let movieDurationStr = '-';
    if (nbTeams > 0) {
      const movieDurationSec = projDurationSec / nbTeams;
      movieDurationStr = this.formatDuration(movieDurationSec);
    }

    return (
      <div className="card block-data">
        <h3>Durée des courts</h3>
        <p className="movie-length">
          Longueur actuelle des court-métrages<br />
          <strong>{movieDurationStr}</strong>
        </p>
        <p>
          Équipes inscrites : {nbTeams}<br />
          Date limite du rendu des courts : 28/11/2019 - 23h
        </p>
      </div>
    )
  }

  renderSearchers() {
    const searchers = this.state.searchers.map(searcher =>
      (<li key={searcher.id}>- {searcher.name} &gt;&gt; {searcher.work}</li>)
    );
    return (
      <div className="card block-search">
        <h3>Ils cherchent une équipe</h3>
        <ul>{searchers}</ul>
      </div>
    );
  }

  renderCharacters() {
    const characters = this.state.characters.map((character, idx) => {
      const isSelected = character.id === this.state.drawn.character;

      let prefix = (idx+1).toFixed(0) + '.';
      if (this.state.drawn.character === '' && idx === this.state.ui.questioned.character) {
        prefix = '??';
      }

      return (<li
        key={character.id}
        className={(isSelected ? 'selected' : '')}
      >{prefix}&nbsp;{character.text}</li>);
    });

    return (
      <div className="card block-characters">
        <h3>Personnages</h3>
        <p>Un ou une...</p>
        <ul>{characters}</ul>
      </div>
    );
  }

  renderPlaces() {
    const places = this.state.places.map((place, idx) => {
      const isSelected = place.id === this.state.drawn.place;

      let prefix = (idx+1).toFixed(0) + '.';
      if (this.state.drawn.place === '' && idx === this.state.ui.questioned.place) {
        prefix = '??';
      }

      return (<li
        key={place.id}
        className={(isSelected ? 'selected' : '')}
      >{prefix}&nbsp;{place.text}</li>);
    });

    return (
      <div className="card block-places">
        <h3>Lieux</h3>
        <ul>{places}</ul>
      </div>
    );
  }

  renderAssets() {
    const assets = this.state.assets.map((asset, idx) => {
      const isSelected = asset.id === this.state.drawn.asset;

      let prefix = (idx+1).toFixed(0) + '.';
      if (this.state.drawn.asset === '' && idx === this.state.ui.questioned.asset) {
        prefix = '??';
      }

      return (<li
        key={asset.id}
        className={(isSelected ? 'selected' : '')}
      >{prefix}&nbsp;{asset.text}</li>);
    });

    return (
      <div className="card block-assets">
        <h3>Objets</h3>
        <ul>{assets}</ul>
      </div>
    );
  }

  renderQuotes() {
    const quotes = this.state.quotes.map((quote, idx) => {
      const isSelected = quote.id === this.state.drawn.quote;

      let prefix = (idx+1).toFixed(0) + '.';
      if (this.state.drawn.quote === '' && idx === this.state.ui.questioned.quote) {
        prefix = '??';
      }

      return (<li
        key={quote.id}
        className={(isSelected ? 'selected' : '')}
      ><blockquote>
        {prefix}&nbsp;&laquo;&nbsp;{quote.text}&nbsp;&raquo;
        <footer><cite>&nbsp;&nbsp;&nbsp;{quote.movie}</cite>, {quote.director}</footer>
      </blockquote></li>);
    });

    return (
      <div className="card block-quotes">
        <h3>Citations</h3>
        <ul>{quotes}</ul>
      </div>
    );
  }

  renderRoulette() {
    const side = this.state.ui.roulette;
    const centerStyle = {
      transform: `rotate(${Math.floor(side * 360 / 8)}deg)`,
    };

    return (
      <div className="roulette block-roulette">
        <span className={`roulette-1${(side === 0) ? ' selected' : ''}`}>1</span>
        <span className={`roulette-2${(side === 1) ? ' selected' : ''}`}>2</span>
        <span className={`roulette-3${(side === 2) ? ' selected' : ''}`}>3</span>
        <span className={`roulette-4${(side === 3) ? ' selected' : ''}`}>4</span>
        <span className={`roulette-5${(side === 4) ? ' selected' : ''}`}>5</span>
        <span className={`roulette-6${(side === 5) ? ' selected' : ''}`}>6</span>
        <span className={`roulette-7${(side === 6) ? ' selected' : ''}`}>7</span>
        <span className={`roulette-8${(side === 7) ? ' selected' : ''}`}>8</span>
        <FontAwesomeIcon className="roulette-center" style={centerStyle} icon="arrow-circle-up" />
      </div>
    );
  }

  renderButtons() {
    const buttons = this.state.ui.buttons.map((button, idx) => {
      let buttonClass = 'button0';
      if (button === 1) {
        buttonClass = 'button1';
      } else if (button === 2) {
        buttonClass = 'button2';
      }
      return (<FontAwesomeIcon className={`button ${buttonClass}`} icon="circle" key={`but${idx}`} />);
    });

    return (
      <div className="block-buttons">
        <p>{buttons}</p>
      </div>
    );
  }

  renderDigits() {
    const digits = this.state.ui.digits;

    return (
      <div className="digits block-digits">
        <p>
          <span className="digits-title">MOV-MANUAL</span>
          {digits.row1.join('')}
        </p>
        <p>
          <span className="digits-title">MOV-FOCUS</span>
          {digits.row2.join('')}
        </p>
        <p>
          <span className="digits-title">MOV-CR9</span>
          {digits.row3.join('')}
        </p>
      </div>
    );
  }

  renderBars() {
    const bars = this.state.ui.bars.map((level, idx) => {
      const barStyle = {
        width: `${level}%`,
      };
      return (<p className="bar" style={barStyle} key={`bar${idx}`}>&nbsp;</p>);
    });

    return (
      <div className="block-bars">
        {bars}
      </div>
    );
  }

  renderProgress() {
    const squares = Array(THEME.length).fill(null).map((_, idx) => {
      let squareClass = '';
      if (idx === this.state.ui.squareNb) {
        squareClass = 'focus';
      } else if (idx < this.state.ui.squareNb) {
        squareClass = 'on';
      }
      return (<p className={squareClass} key={`prog${idx}`}>{THEME[idx]}</p>);
    });
    return (
      <div className="progress block-progress">
        {squares}
      </div>
    );
  }
}

export default withFirebase(Dashboard);
